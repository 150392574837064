

import {
  Box,
  Text,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  ListItem,
  UnorderedList
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';




export default function CPConsulting() {
  const { isOpen, onToggle } = useDisclosure()

  return (
    <Box>
     
      
      {/* Sisu kuvamine */}
      <Box
        p={{ base: 4, md: 10 }}
        bg={useColorModeValue('gray.50', 'gray.700')}
        color={useColorModeValue('gray.800', 'white')}
        mt={{ base: 4, md: 8 }}
       ml={20}
       mr={8}
      >
        <Text as="h2" fontSize="2xl" fontWeight="bold" mb={4}>
          IT-konsultatsiooniteenuseid Perearstikeskustele
        </Text>
        <Text mb={2}>
         <Text  as="h3" fontSize="lg"  mb={3} ml={16} mr={10}>
         Meie eesmärk on pakkuda Perearstikeskustele asjatundlikku nõu ja praktilist abi, et infoturbe nõuete täitmine oleks 
         teostatav ja vastaks seadustele. Meie teenused on kohandatud, et toetada Teid 
         kõigis infoturbe standardiga - <strong>E-ITS</strong> seotud küsimustes ja tagada, et Teie patsientide andmed oleks 
         kaitstud ja IT-süsteemid ning protsessid turvalised.
        </Text>
        </Text>
        <Text mb={2}>
           Meie teenused hõlmavad järgmist:
        </Text>
        <Text as="h3" fontSize="lg" fontWeight="bold" mt={4}>
          1. IT-alase Seisukorra Analüüs
        </Text>
        <Text>
          Me viime läbi põhjaliku ülevaate Teie perearstikeskuse praegusest IT-infrastruktuurist ja protsessidest, et tuvastada kõrvalekalded E-ITS standardi nõuetest. Selle analüüsi tulemusena koostame detailse plaani, mis kirjeldab vajalikke muudatusi, et saavutada täielik vastavus infoturbenõuetega.
        </Text>
        <Text as="h3" fontSize="lg" fontWeight="bold" mt={4}>
          2. Dokumentatsiooni Koostamine
        </Text>
        <Text>
          Aitame Teid oluliste dokumentide koostamisel, mis on vajalikud E-ITS standardi nõuetele vastavuse tagamiseks. Nende hulka kuuluvad:
        </Text>
        <UnorderedList ml={8} my={2}>
          <ListItem>
            <i>Infoturbepoliitika Dokumentatsioon</i>: Selles dokumendis sätestatakse Teie keskuse infoturbe põhimõtted ja protseduurid.
          </ListItem>
          <ListItem>
            <i>Arvutivõrkude ja IT-süsteemide Dokumentatsioon:</i> See dokument kajastab Teie IT-infrastruktuuri ja võrgu konfiguratsiooni detaile.
          </ListItem>
          <ListItem>
            <i>Arvutivõrgu Kasutuseeskirjad:</i> Kergesti arusaadavad eeskirjad PAK töötajatele juhisteks ja järgimiseks, et   tagada võrgu turvaline ja efektiivne kasutus.
          </ListItem>
          <ListItem>
            <i>Riskianalüüs ja Turvaeetmed</i> Koostame riskianalüüsi, mis tuvastab võimalikud ohud ja pakub välja meetmed nende ennetamiseks.
          </ListItem>
        </UnorderedList>
        <Text as="h3" fontSize="lg" fontWeight="bold" mt={4}>
          3. Pidev toetus
        </Text>
        <Text mb={2}>
          Kui Riigi Infosüsteemi Amet (RIA) teostab Teie perearstikeskuses ülevaatust, abistame Teid  kogu protsessi vältel, et tagada sujuv kontroll ja abistada kõigis vajalikes aspektides, et tagada vastavus E-ITS standardile.
        </Text>
        
      </Box>
    </Box>
  )
}


