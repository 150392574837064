import * as React from "react"
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
} from "@chakra-ui/react"
import { ColorModeSwitcher } from "./ColorModeSwitcher"

import { 
  createBrowserRouter, 
  createRoutesFromElements, 
  Route, 
  RouterProvider 
} from 'react-router-dom';

// layouts and pages
import RootLayout from './layouts/RootLayout'
import HowHelp from "./components/HowHelp";
import CPServices from "./components/CPServices";
import CPConsulting from "./components/CPConsulting";
import CPDevs from "./components/CPDevs";
import CPContact from "./components/CPContact";
import CPTranslate from "./components/CPTranslate";


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<HowHelp />}></Route>
      <Route path="services" element={< CPServices/>}></Route>
      <Route path="consulting" element={< CPConsulting/>}></Route>
      <Route path="devs" element={< CPDevs/>}></Route>
      <Route path="contact" element={< CPContact/>}></Route>
      <Route path="translate" element={< CPTranslate/>}></Route>
    </Route>
  )
)

function App() {
  return (
    <RouterProvider router={router} />
  )
}

export default App

