import React from 'react';
import { Box, Container, Stack, SimpleGrid, Text, VisuallyHidden, useColorModeValue, Button, Table } from '@chakra-ui/react';
import { FaTwitter, FaYoutube, FaInstagram } from 'react-icons/fa';




const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  );
};

const SocialButton = ({ children, label, href }) => {
  return (
    <Button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={10}
      h={10}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}>
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </Button>
  );
};

export default function Footer() {
  return (
    <Box
    
      bg={useColorModeValue('gray.500', 'gray.400')}
      color={useColorModeValue('gray.200', 'gray.600')}
      rounded={10}
      mt={20}
      
      >
      <Container as={Stack} maxW={'6xl'} py={10}>
        <SimpleGrid columns={{ base: 1, sm: 2, md: 2 }} spacing={3}>
          <Stack align={'flex-start'}>
            <ListHeader>Cyber Planet OÜ</ListHeader>
            
            <Table border="1" padding={4}>
    
                <tbody>
                <tr>
                        <td> </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Registrikood: </td>
                        <td>14037478</td>
                    </tr>
                    
                    <tr>
                        <td>Registreeritud: </td>
                        <td>25.04.2016</td>
                    </tr>
                  
                </tbody>
            </Table>

           
            
           
          </Stack>

          <Stack align={'flex-start'}>
            <ListHeader></ListHeader>
            
        <Table border="1" >
    
            <tbody>
                <tr>
                    <td>Aadress: </td>
                    <td>Harju maakond, Tallinn, Kesklinna linnaosa, Tatari tn 25, 10116 </td>
                </tr>
                
                <tr>
                    <td>email: </td>
                    <td>info@cyberplanet.tech</td>
                </tr>

                <tr>
                    <td>telefon: </td>
                    <td>+3725058061</td>
                </tr>
              
            </tbody>
        </Table>
          </Stack>

        
          
        </SimpleGrid>
      </Container>

      <Box
        borderTopWidth={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.700')}>
        <Container
          as={Stack}
          maxW={'6xl'}
          py={4}
          direction={{ base: 'column', md: 'row' }}
          spacing={4}
          justify={{ md: 'space-between' }}
          align={{ md: 'center' }}>
          <Text>© 2023 Cyber Planet. All rights reserved</Text>
          <Stack direction={'row'} spacing={6}>
            <SocialButton label={'Twitter'} href={'#'}>
              <FaTwitter />
            </SocialButton>
            <SocialButton label={'YouTube'} href={'#'}>
              <FaYoutube />
            </SocialButton>
            <SocialButton label={'Instagram'} href={'#'}>
              <FaInstagram />
            </SocialButton>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
