import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    Icon,
    Stack,
    Text,
    Link,
    useColorModeValue,
  } from '@chakra-ui/react'
  import { ReactElement } from 'react'
  import {
    FcAbout,
    FcAssistant,
    FcCollaboration,
    FcDonate,
    FcManager,
  } from 'react-icons/fc'
  
  const Card = ({ heading, description, icon, href }) => {
    return (
      <Box
        maxW={{ base: 'full', md: '370px' }}
        w={'full'}
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        shadow={'xl'}
        border={'1px solid'}
        rounded={10}
        p={8}>
        <Stack align={'start'} spacing={2}>
          
          <Box mt={2}>
            <Heading size="md">{heading}</Heading>
            <Text mt={1} fontSize={'2sm'}>
              {description}
            </Text>
          </Box>
          <Link href={href} style={{ textDecoration: 'none' }}>
          <Button variant={'link'} colorScheme={'blue'} size={'3sm'} >
            Uuri veel
          </Button>
          </Link>
        </Stack>
      </Box>
    )
  }

  const ContactCard = ({ heading, description, icon, href }) => {
    return (
      <Box
        maxW={{ base: 'full', md: '370px' }}
        w={'full'}
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        shadow={'xl'}
        border={'1px solid'}
        rounded={10}
        p={8}>
        <Stack align={'start'} spacing={2}>
          
          <Box mt={2}>
            <Heading size="md">{heading}</Heading>
            <Text mt={1} fontSize={'2sm'}>
              {description}
            </Text>
          </Box>
          <Link href={href} style={{ textDecoration: 'none' }}>
          <Button variant={'link'} colorScheme={'blue'} size={'3sm'} >
            info@cyberplanet.tech
          </Button>
          </Link>
        </Stack>
      </Box>
    )
  }
  
  export default function HowHelp() {
    return (
      <Box p={4} mt={4}>
        <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
          <Heading fontSize={{ base: '2xl', sm: '4xl' }} fontWeight={'bold'}>
            Kuidas saame aidata
          </Heading>
          
        </Stack>
  
        <Container maxW={'5xl'} mt={6} mb={16}>
          <Flex flexWrap="wrap" gridGap={6} justify="center">
            <Card
              heading={'IT alased konsultatsioonid'}
              
              description={'Pakume Perearstikeskustele  IT-konsultatsioone, tagades, et Teie süsteemid vastaksid E-ITS standardile.'}
              href={'consulting'}
            />
            <Card
              heading={'Tarkvaraarendus'}
              icon={<Icon as={FcCollaboration} w={10} h={10} />}
              description={'Veebiarendus, Suurandmete analüüs, Tehisintellekti juurutamine, Web3 '}
              href={'devs'}
            />
            <Card
              heading={'Tõlked'}
              icon={<Icon as={FcDonate} w={10} h={10} />}
              description={'Tehisintellekt on küll hea vahend kuid  vahel peab tundma ka kultuuri milles keelt kasutatakse'}
              href={'translate'}
            />
            <ContactCard
              heading={'Midagi muud?'}
              icon={<Icon as={FcManager} w={10} h={10} />}
              description={'Esita küsimus/ettepanek'}
              href={'mailto:info@cyberplanet.tech'}
            />
            
          </Flex>
        </Container>
      </Box>
    )
  }
  