import {
    Container,
    Flex,
    Box,
    Heading,
    Text,
    IconButton,
    Button,
    VStack,
    HStack,
    Wrap,
    WrapItem,
    
    Table
  } from '@chakra-ui/react';
  

  import React, { useState } from 'react';
  
  export default function CPContact() {

    // State vormi andmete jaoks
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    // Defineerime Unicode'i sümbolid
    const phoneSymbol = "\u260E"; // Telefon
    const emailSymbol = "\u2709"; // Email
    const locationSymbol = "\u2691"; // Asukoht

    // Funktsioon vormi andmete käsitsemiseks
    const handleSubmit = async (event) => {
      event.preventDefault();
    
      // Formi andmete kokku kogumine
      const formData = {
        name: "Nimi",
        email: "kasutaja@email.com",
        message: "See on test sõnum."
      };
    
      // Saada andmed serverisse fetch API abil
      try {
        const response = await fetch('https://cyberplanet.tech/send-email.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData), // konverteeri JavaScripti objekt JSON stringiks
        });
    
        if (response.ok) {
          const jsonResponse = await response.json();
          console.log("Sõnum saadetud: ", jsonResponse);
        } else {
          throw new Error('Midagi läks valesti!');
        }
      } catch (error) {
        console.error("E-kirja saatmine ebaõnnestus: ", error);
      }
    };
    

    return (
      <Container maxW="full" mt={0} centerContent overflow="hidden" bg="gray.100">
        <Flex>
          <Box
           
            bg={'gray.100'}
            color={'gray.600'}
            borderRadius="md"
            m={{ sm: 4, md: 16, lg: 10 }}
            p={{ sm: 5, md: 5, lg: 16 }}>
            <Box p={4}>
              <Wrap spacing={{ base: 10, sm: 4, md: 5, lg: 10 }}>
                <WrapItem>
                  <Box>
                    <Heading>Cyber Planet OÜ</Heading>
                    
                    <Table 
                      border="1" 
                      padding={8}
                      mt={{ sm: 4, md: 6, lg: 8 }} 
                      spacing={{ base: 10, sm: 4, md: 5, lg: 10 }}
                      color="gray.500"
                      >
    
                <tbody >
                <tr>
                        <td> </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Address: </td>
                        <td>Harju maakond, Tallinn, Kesklinna linnaosa, Tatari tn 25, 10116</td>
                    </tr>
                    <tr>
                        <td>Registrikood: </td>
                        <td>14037478</td>
                    </tr>
                    
                    <tr>
                        <td>Registreeritud: </td>
                        <td>25.04.2016</td>
                    </tr>
                  
                </tbody>
            </Table>
                    <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                      <VStack pl={0} spacing={3} alignItems="flex-start">
                        <Button
                          size="md"
                          height="48px"
                          width="200px"
                          variant="ghost"
                          color="black"
                          _hover={{ border: '2px solid #1C6FEB' }}
                          leftIcon={<span style={{fontSize: '20px'}}>{phoneSymbol}</span>}> {/* Kasutame Unicode sümbolit */}
                          +372 5058061
                        </Button>
                        <Button
                          size="md"
                          height="48px"
                          width="200px"
                          variant="ghost"
                          color="black"
                          _hover={{ border: '2px solid #1C6FEB' }}
                          leftIcon={<span style={{fontSize: '20px'}}>{emailSymbol}</span>}> {/* Kasutame Unicode sümbolit */}
                          info@cyberplanet.tech
                        </Button>
                        
                      </VStack>
                    </Box>
                    <HStack
                      mt={{ lg: 10, md: 10 }}
                      spacing={5}
                      px={5}
                      alignItems="flex-start">
                      
                      
                     
                    </HStack>
                  </Box>
                </WrapItem>
                
              </Wrap>
            </Box>
          </Box>
        </Flex>
      </Container>
    );
  }
  