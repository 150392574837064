import React from 'react';
import {
  Container,
  Box,
  Heading,
  Text,
  Button,
  VStack,
  Card
} from '@chakra-ui/react';

export default function CPTranslate() {
  return (
    <Container maxW="container.xl" p={6}>
      <VStack spacing={8} align="stretch">
        <Box p={8} shadow="md" borderWidth="1px">
          <Heading mb={6}>Professionaalsed tõlketeenused</Heading>
          <Card p={4} shadow="md" borderWidth="0.25px">
         
          <Text fontSize="lg" mb={3} ml={20} fontWeight={'bold'}>
          紙上談兵 - Mängib sõda paberi peal </Text>
          <Text fontSize="md" mb={2} ml={22} align={'end'}>
          (Hiina vanasõna - viitab olukorrale, kus keegi räägib teoreetiliselt või spekuleerib ilma praktilise kogemuseta)
          </Text>
          </Card>
          
          
          <Text mt={4} mb={3} size="h2" fontSize={22}>
            Kas vajate <strong>Hiinakeelsete</strong> tehniliste dokumentide tõlget või soovite mõista kultuuriliste tekstide peensusi?
            Meie kogenud tõlkemeeskond pakub kvaliteetseid tõlketeenuseid Hiina keelest Eesti keelde.
          </Text>
          <Heading size="md" mb={2}>1. Tehnilised tõlked</Heading>
          <Text mb={3}>
            Meie teenused hõlmavad täpset tehnilist tõlget ja terminite sõnastikku, tagades, et kõik
            tehnilised terminid ja kontseptsioonid on täpselt edasi antud.
          </Text>
          <Heading size="md" mb={2}>2. Kultuuriliste ja sotsiaalsete tekstide tõlked</Heading>
          <Text mb={3}>
            Kultuuriliselt rikkad tekstid nagu tätoveeringute kirjad on enam kui lihtsalt tõlked – need on
            sild erinevate kultuuride vahel. Meie tõlked püüavad kinni originaali tooni ja emotsiooni.
          </Text>
          
        </Box>
      </VStack>
    </Container>
  );
}
